<template>
  <div class="page-container-new">
    <div class="earning-page">
      <h2>
        판매가 종료된 이후에<br />
        빅크에서 개별 연락 드립니다.
      </h2>

      <img
        class="empty-pc"
        src="/assets/images/reports/reports_pc_2x_comp.png"
      />

      <div class="empty-mo">
        <img class="" src="/assets/images/reports/reports_mo_top_2x_comp.png" />
        <img
          class=""
          src="/assets/images/reports/reports_mo_bottom_2x_comp.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Earning",
};
</script>

<style src="./style.css" scoped></style>
